import "./index.scss";
import Logo from "../../assets/images/Logo.png";
import Graffiti from "../../assets/images/graffiti.png";
import { Link } from "@mui/material/";
import { Link as RouterLink } from "react-router-dom";

const Header = () => {
  return (
    <header className="header">
      <Link className="router-link" component={RouterLink} to="/">
        <img
          className="header__logo"
          src={Logo}
          alt="header__logo"
          sizes="(max-width: 320px) 280px,
            (max-width: 480px) 440px,
            800px"
          height="100px"
        />
      </Link>
      <img
        className="header__right-image"
        src={Graffiti}
        alt="logo"
        height="100px"
      />
    </header>
  );
};

export { Header };
