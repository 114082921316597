import { Link, Typography, Divider } from "@mui/material/";
import { Link as RouterLink } from "react-router-dom";
import Anon from "../../assets/images/anon.png";
import Person from "../../assets/images/person1.png";
import "./index.scss";
import { Container } from "@mui/system";

const ChoicePersonalization = () => {
  return (
    <Container
      className="container"
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "2rem",
        paddingBottom: "40px",
      }}
    >
      <Typography variant="h3" sx={{ textAlign: "center" }}>
        Оставить отзыв
      </Typography>
      <div className="links-block">
        <Link
          component={RouterLink}
          to="/anonymous"
          textAlign="center"
          underline="always"
          color="primary"
        >
          <div className="links-block__item links-block__item_link">
            <img
              className="links-block__img"
              src={Anon}
              alt="anonymous"
              width="75px"
            />
            <Typography variant="h6">Анонимно</Typography>
          </div>
        </Link>
        <div className="links-block__item links-block__item_divider">
          <Divider orientation="vertical" />
        </div>
        <Link
          component={RouterLink}
          to="/person"
          textAlign="center"
          underline="always"
          color="primary"
        >
          <div className="links-block__item links-block__item_link">
            <img
              className="links-block__img"
              src={Person}
              alt="person"
              width="75px"
            />
            <Typography variant="h6">От своего имени</Typography>
          </div>
        </Link>
      </div>
    </Container>
  );
};

export { ChoicePersonalization };
