import "./index.scss";
import { useLocation } from "react-router-dom";
import { Link, Typography, Container } from "@mui/material/";
import { Link as RouterLink } from "react-router-dom";
import Anon from "../../assets/images/anon.png";
import Person from "../../assets/images/person.png";

const ChoiseVariant = () => {
  const { pathname } = useLocation();

  return (
    <Container
      maxWidth="md"
      sx={{
        flexGrow: "1",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "2rem",
      }}
    >
      <Link
        component={RouterLink}
        to={`${pathname}/template`}
        textAlign="center"
        underline="always"
        color="primary"
      >
        <Typography variant="h5">По шаблону</Typography>
      </Link>
      <img
        className="img"
        src={pathname === "/anonymous" ? Anon : Person}
        alt=""
        width="100px"
      />
      <Link
        component={RouterLink}
        to={`${pathname}/custom`}
        textAlign="center"
        underline="always"
        color="primary"
      >
        <Typography variant="h5">В произвольной форме</Typography>
      </Link>
    </Container>
  );
};

export { ChoiseVariant };
