import * as yup from "yup";

export const validationSchemaPerson = yup.object({
  comment: yup
    .string("Опишите вашу проблему")
    .required("Опишите вашу проблему"),
  name: yup.string("Представьтесь").required("Представьтесь"),
});
export const validationSchemaAnonymous = yup.object({
  comment: yup
    .string("Опишите вашу проблему")
    .required("Опишите вашу проблему"),
});
