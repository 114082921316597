import React, { useState } from "react";

import "./App.scss";
import { Routes, Route } from "react-router-dom";
import { Header, BackButton } from "./components";
import {
  ChoicePersonalization,
  ChoiseVariant,
  CustomComplaint,
  TemplateComplaint,
} from "./views";
import { ThemeProvider, Snackbar, Alert } from "@mui/material";
import { theme } from "./utils/theme";
import mailbox from "./assets/images/mailbox.png";
import { CssBaseline } from "@mui/material";
import { useLocation } from "react-router-dom";

const App = () => {
  const [isOpenSucces, setOpenSuccess] = useState(false);
  const [isOpenError, setOpenError] = useState(null);
  const { pathname } = useLocation();

  const handleClose = () => {
    setOpenError(null);
    setOpenSuccess(false);
  };

  const openSuccess = (value) => {
    setOpenSuccess(value);
  };

  const openError = (value) => {
    setOpenError(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <div className="wrapper">
        <Header />
        {pathname !== "/" && (
          <BackButton
            sx={{
              color: "primary",
              margin: "0 0 5px 15px",
            }}
          />
        )}
        {/* <BackButton
          sx={{
            color: "primary",
            margin: "0 0 5px 15px",
          }}
        /> */}
        <Routes>
          <Route path="/" element={<ChoicePersonalization />}></Route>
          <Route path="/anonymous" element={<ChoiseVariant />}></Route>
          <Route path="/person" element={<ChoiseVariant />}></Route>
          <Route
            path="/anonymous/custom"
            element={
              <CustomComplaint
                openSuccess={openSuccess}
                openError={openError}
              />
            }
          ></Route>
          <Route
            path="/anonymous/template"
            element={
              <TemplateComplaint
                openSuccess={openSuccess}
                openError={openError}
              />
            }
          ></Route>
          <Route
            path="/person/template"
            element={
              <TemplateComplaint
                openSuccess={openSuccess}
                openError={openError}
              />
            }
          ></Route>
          <Route
            path="/person/custom"
            element={
              <CustomComplaint
                openSuccess={openSuccess}
                openError={openError}
              />
            }
          ></Route>
          <Route path="*" element={<ChoicePersonalization />}></Route>
        </Routes>
        <img
          className="mailbox-image"
          src={mailbox}
          height="250px"
          alt="mailbox"
        />
      </div>
      <Snackbar
        open={Boolean(isOpenSucces)}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
          variant="filled"
        >
          Жалоба успешно отправлена
        </Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(isOpenError)}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
          variant="filled"
        >
          Жалоба не отправлена: {isOpenError}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default App;
