export const question = [
  {
    id: 0,
    title: "Вы испытываете отрицательные эмоции в общении",
    supplement: "С чьей стороны вы испытываете негативное воздействие:",
    points: [
      { text: "Вас регулярно оскорбляет коллега/коллеги/руководитель?" },
      {
        text: "Вас изолируют от рабочих процессов. Вы не чувствуете себя частью команды?",
      },
      { text: "Ваши инициативы не воспринимают в серьез и/или высмеивают?" },
      {
        text: "Вы часто становитесь свидетелем, что ваш коллега/коллеги/руководитель манипулируют (провоцируют) конфликтные ситуации, пытаются самоутвердится за счет коллег и подчиненных.",
      },
      {
        text: "Вас ограничивают в ресурсах? (С вами не делятся информацией касающийся вашей работы, вам не дают право голоса в общих инициативах, что бы получить необходимый «инструмент» для вашей работы вам приходится подолгу ждать в отличие от других )",
      },
      { text: "Нарушаются личные границы?" },
      { text: "В ваш адрес регулярно поступают оскорбительные шутки?" },
      { text: "В ваш адрес применялось физическое насилие?" },
      { text: "В ваш адрес применялось сексуальное насилие?" },
    ],
  },
  {
    id: 1,
    title: "Вы стали свидетелем негативного воздействия на вашего коллегу",
    supplement: "Кто был инициатором:",
    points: [
      { text: "Сплетен в адрес других сотрудников?" },
      {
        text: "Скрытого саботажа со стороны коллег? (Ваш коллега неудовлетворен работой, злоба на коллектив или начальника следствие - отказ выполнять свои обязанности, отлынивание от работы).",
      },
      {
        text: "Свидетелем психологического/физической воздействия в адрес коллеги?",
      },
      {
        text: " Вы наблюдаете что руководитель оценивает не саму работу, а личность исполнителя, оскорбляя или унижая подчинённых?",
      },
      {
        text: " Вы становились свидетелем конфликтов, которые не связаны с рабочими вопросами? (Конфликты между коллегами, которые не относятся к работе, но последствия обычно негативно влияют на рабочий процесс, т.к конфликтующие лица находятся в негативном настрое к друг другу по личной причине).",
      },
    ],
  },
  {
    id: 2,
    title: "На кого из сотрудников следует обратить внимание?",
    supplement: "На кого из сотрудников следует обратить внимание:",
    points: [
      {
        text: "Вы стали свидетелем как ваш коллега/руководитель не выполняет свои рабочие обязанности?",
      },
      {
        text: "Ваш коллега/коллеги/руководитель не желает работать. В итоге он/она создает имитацию бурной деятельности, а компания не получает никакого результата.",
      },
      {
        text: "Ваш коллега/коллеги/руководитель часто не отвечает на входящие телефонные звонки. При этом он явно занят не работой.",
      },
      {
        text: "Ваш коллега/коллеги/руководитель перекладывает обязанности на коллег (преимущественно должностью ниже).",
      },
      {
        text: "Ваш коллега/коллеги/руководитель провоцирует конфликтные ситуации, пытается вас разозлить, расстроить или выставить вас в дурном свете.",
      },
    ],
  },
  {
    id: 3,
    title: "Расскажите кому нужна помощь",
    supplement:
      "Мне кажется моему коллеге нужна помощь, любимая работа стала приносить разочарование и вселять неуверенность, в разговорах появляется фраза что пора менять работу. Возможно, мы сможете принять нужные меры и решить проблему, из-за которой он хочет уйти. Расскажите кому нужна помощь или если вы не нашли выше вашей проблемы опишите ее в этом пункте самостоятельно:",
    points: [],
  },
];
