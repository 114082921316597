import { Container, TextField, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  validationSchemaAnonymous,
  validationSchemaPerson,
} from "./validationSchemas";
import "./index.scss";

const CustomComplaint = ({ openSuccess, openError }) => {
  const { pathname } = useLocation();
  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      comment: "",
    },
    validationSchema:
      pathname === "/person/custom"
        ? validationSchemaPerson
        : validationSchemaAnonymous,
    onSubmit: async (values, { resetForm }) => {
      const postBody = {
        text: values.comment,
        a9: pathname === "/person/custom" ? values.name : "anonymous",
      };
      try {
        let response = await fetch(
          "https://my.carolinaspb.ru/api/json/add/complaint",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(postBody),
          }
        );
        if (!response.ok) {
          throw new Error("network error");
        }
        resetForm();
        openSuccess(true);
        navigate("/", { replace: true });
      } catch (e) {
        openError(e.message);
        console.log("error");
      }
    },
  });

  return (
    <Container
      maxWidth="md"
      sx={{
        borderRadius: "0.5rem",
        padding: "1rem 1rem 3rem 1rem",
        backgroundColor: "white",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        {pathname === "/person/custom" && (
          <TextField
            fullWidth
            variant="outlined"
            sx={{ marginBottom: "1rem" }}
            label="Ваши имя, фамилия"
            id="name"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        )}
        <TextField
          fullWidth
          sx={{ marginBottom: "1rem" }}
          multiline
          label="Опишите вашу проблему"
          minRows={3}
          id="comment"
          name="comment"
          value={formik.values.comment}
          onChange={formik.handleChange}
          error={formik.touched.comment && Boolean(formik.errors.comment)}
          helperText={formik.touched.comment && formik.errors.comment}
        />
        <Button
          fullWidth
          color="primary"
          variant="contained"
          type="submit"
          disabled={!formik.values.comment.length}
        >
          Отправить
        </Button>
      </form>
    </Container>
  );
};

export { CustomComplaint };
