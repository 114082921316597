import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  FormGroup,
  FormControlLabel,
  Checkbox,
  MenuItem,
  InputLabel,
} from "@mui/material";
import "./index.scss";
import { Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";

import { question } from "../../utils/questions";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import {
  validationSchemaAnonymous,
  validationSchemaPerson,
} from "./validationsSchemas";
import { Select } from "@mui/material";

const TemplateComplaint = ({ openSuccess, openError }) => {
  const { pathname } = useLocation();
  let navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      comment: "",
      points: [],
      category: question[0],
    },
    validationSchema:
      pathname === "/anonymous/template"
        ? validationSchemaAnonymous
        : validationSchemaPerson,
    onSubmit: async (values, { resetForm }) => {
      const postBody = {
        text: values.comment,
        a9: pathname === "/anonymous/template" ? "anonymous" : values.name,
        tema: values.category.title,
      };
      values.points.forEach((item, index) => {
        if (index === 8) {
          postBody["a10"] = item;
          return;
        }
        postBody[`a${index + 1}`] = item;
      });
      try {
        let response = await fetch(
          "https://my.carolinaspb.ru/api/json/add/complaint",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(postBody),
          }
        );
        if (!response.ok) {
          throw new Error("network error");
        }
        openSuccess(true);
        navigate("/", { replace: true });
        resetForm({
          values: { name: "", comment: "", points: [], category: question[0] },
        });
      } catch (e) {
        openError(e.message);
      }
    },
  });

  return (
    <Container
      maxWidth="md"
      sx={{
        borderRadius: "0.5rem",
        padding: "1rem 1rem 3rem 1rem",
        backgroundColor: "white",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <FormControl fullWidth>
          <InputLabel id="select-label">Выберите категорию</InputLabel>
          <Select
            name="category"
            id="category"
            labelId="select-label"
            value={formik.values.category}
            label="Выберите категорию"
            onChange={formik.handleChange}
          >
            {question.map((item) => {
              return (
                <MenuItem
                  value={item}
                  key={item.id}
                  sx={{ whiteSpace: "normal", wordBreak: "keep-all" }}
                >
                  {item.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormGroup sx={{ marginBottom: "1rem" }}>
          {formik.values.category?.points.map((item, index) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={formik.handleChange}
                    id={`checkbox_${index}`}
                    name={"points"}
                    value={item.text}
                  />
                }
                label={item.text}
                key={item.text}
              />
            );
          })}
        </FormGroup>
        {pathname === "/person/template" && (
          <>
            <Typography>Представьтесь:</Typography>
            <TextField
              variant="outlined"
              fullWidth
              sx={{ marginBottom: "1rem", marginTop: "0.4rem" }}
              id="name"
              name="name"
              label="Ваши имя, фамилия"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </>
        )}
        <Typography>{formik.values.category?.supplement}</Typography>
        <TextField
          variant="outlined"
          minRows={2}
          fullWidth
          multiline
          sx={{ marginBottom: "1rem", marginTop: "0.4rem" }}
          id="comment"
          name="comment"
          label="Ваш комментарий"
          value={formik.values.comment}
          onChange={formik.handleChange}
          error={formik.touched.comment && Boolean(formik.errors.comment)}
          helperText={formik.touched.comment && formik.errors.comment}
        />
        <Button
          fullWidth
          variant="contained"
          type="submit"
          disabled={!formik.values.comment.length}
        >
          Отправить жалобу
        </Button>
      </form>
    </Container>
  );
};

export { TemplateComplaint };
