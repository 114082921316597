import Fab from "@mui/material/Fab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const BackButton = ({ sx }) => {
  let navigate = useNavigate();
  return (
    <div>
      <Fab size="small" color="secondary" sx={sx} onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </Fab>
    </div>
  );
};

export { BackButton };
